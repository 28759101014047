function getFileImageNode(name, allFileImageNodes) {
  const imageNameJpg = name.toLowerCase() + ".jpg"
  const imageNamePng = name.toLowerCase() + ".png"
  const fileImageNode = allFileImageNodes.filter(imageNode => {
    console.log(
      `Checking if ${imageNode.childImageSharp.fluid.originalName} is equal to ${name}`
    )
    return (
      imageNode.childImageSharp.fluid.originalName === imageNameJpg ||
      imageNode.childImageSharp.fluid.originalName === imageNamePng
    )
  })

  if (fileImageNode.length === 1) {
    return fileImageNode[0]
  }
  return null
}

function getImageForName(name, type, imageNodes) {
  const imageNameJpg = name.toLowerCase() + "_" + type.toLowerCase() + ".jpg"
  const imageNamePng = name.toLowerCase() + "_" + type.toLowerCase() + ".png"
  const resultingNodes = imageNodes.filter(imageNode => {
    // console.log(
    //   `Checking if ${imageNode.childImageSharp.fluid.originalName} is equal to ${imageName}`
    // )
    return (
      imageNode.childImageSharp.fluid.originalName === imageNameJpg ||
      imageNode.childImageSharp.fluid.originalName === imageNamePng
    )
  })

  if (resultingNodes.length === 1) {
    return resultingNodes[0].childImageSharp.fluid
  }
  return null
}

module.exports = {
  getFileImageNode: getFileImageNode,
  getImageForName: getImageForName,
}
