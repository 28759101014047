import React from "react"

export default function TilesSection(props) {
  const children = props.children
  const title = props.title

  return (
    <section className="page-section bg-light" id="portfolio">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">{title}</h2>
          <h3 className="section-subheading text-muted">
            {/* Lorem ipsum dolor sit amet consectetur. */}
          </h3>
        </div>

        <div>{children}</div>
      </div>
    </section>
  )
}
