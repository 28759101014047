import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

export default function GeneralTile({ node }) {
  // console.log("GeneralTile item is : ", node)
  // const imgFluid = node.imgFluid ? node.imgFluid : ""
  // if (node.imgFluid !== null) {
  //   console.log("this is the node.imgFluid", node.imgFluid)
  // }
  // console.log("node.url is", node.url)
  // console.log("node. is", node)

  const [imgSnipped, setImgSnipped] = useState()

  const url = node.url ? node.url : "#"
  console.log("generaltile.js: node is", node)

  useEffect(() => {
    // console.log(`********Setting image for ${node.name}`)

    node.fileImageNode == null
      ? setImgSnipped(
          <img
            className="img-fluid"
            src="/img/placeholder.jpg"
            alt={node.name}
          />
        )
      : setImgSnipped(
          <GatsbyImage
            image={node.fileImageNode.childImageSharp.gatsbyImageData}
            alt={node.name}
          />
        )
  }, [node])

  return (
    <div className="col-lg-4 col-sm-6 mb-4">
      <div className="portfolio-item">
        <Link className="portfolio-link" data-toggle="modal" to={url}>
          <div className="portfolio-hover">
            <div className="portfolio-hover-content">
              <i className="fas fa-plus fa-3x"></i>
            </div>
          </div>
          {/* <img className="img-fluid" src="/img/portfolio/01-thumbnail.jpg" alt="" /> */}

          {imgSnipped}
        </Link>
        <div className="portfolio-caption">
          <div className="portfolio-caption-heading">{node.name}</div>
          {/* <div className="portfolio-caption-subheading text-muted">
            Illustration {node.id}
          </div> */}
        </div>
      </div>
    </div>
  )
}
